import { showLoader } from '../../helpers';
import { axiosAjaxCall } from './common.service';
export const varificationService = {
    getIndividuals,
    getIndividualDetail,
    verifyIndividualCompany,
    addToJunkUser,
    fetchAndVerify ,
    aadharReject, 
    saveGstUpdate,


    getCompanies,
    getCompanyDetail,
    updateCompany,
    updateIndividual
}



function saveGstUpdate(data) {
    return axiosAjaxCall("update_gst", data);
}

function aadharReject(data) {
    return axiosAjaxCall("reject-aadhaar-details", data);
}
function getIndividuals(data) {
    
    return axiosAjaxCall("get_individual_list", data);
}
function addToJunkUser(data) {
    
    return axiosAjaxCall("update_junk_individual", data);
}
function fetchAndVerify(data) {
    
    return axiosAjaxCall("auto_verify", data);
}

function getIndividualDetail(data) {
    return axiosAjaxCall("get_individual_detail", data);
}

function verifyIndividualCompany(data) {
    showLoader(); 
    return axiosAjaxCall("verify_document", data);
}

function getCompanies(data) {
    return axiosAjaxCall("get_company_list", data);
}

function getCompanyDetail(data) {
    return axiosAjaxCall("get_company_detail", data);
}

function updateCompany(data) {
    return axiosAjaxCall("update_company", data);
}

function updateIndividual(data) {
    return axiosAjaxCall("update_individual", data);
}